import { render, staticRenderFns } from "./PlayfieldOrder.vue?vue&type=template&id=7884ebce&scoped=true"
import script from "./PlayfieldOrder.vue?vue&type=script&lang=js"
export * from "./PlayfieldOrder.vue?vue&type=script&lang=js"
import style0 from "./PlayfieldOrder.vue?vue&type=style&index=0&id=7884ebce&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7884ebce",
  null
  
)

export default component.exports